//system
import React from 'react'

import { graphql } from 'gatsby'
import { Link } from 'gatsby'

//components
import LayoutMain from '../com/LayoutMain'
import Container from '../com/Container'
import Section from '../com/Section'
import Diagonal from '../com/Diagonal'
import Cta from '../com/Cta'
import CtaButton from '../com/CtaButton'
import H1 from '../com/H1'
import H2 from '../com/H2'

import P from '../com/P'
import Footer from '../com/Footer'

const TermsPage = ({ data }) => {
  return (
    <LayoutMain>
      <header>
        <Diagonal>
          <Container>
            <H1>Dec Hands Ltd Terms &amp; Conditions</H1>
            <CtaButton>
              <a href="/dec-hands-terms-and-conditions.pdf" download target="_blank" style={{ padding: '0' }}>
                Download PDF
              </a>
            </CtaButton>
          </Container>
        </Diagonal>
      </header>
      <Section>
        {/* <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} /> */}

        <Container>
          <H2>When do our terms apply?</H2>
          <P>
            Dec Hands Terms &amp; conditions apply whenever we carry out any services for you. Please{' '}
            <a href="/dec-hands-terms-and-conditions.pdf" download target="_blank">
              download
            </a>{' '}
            and read a copy from this page before our work commences.
          </P>
          <P>
            If we have asked you to sign a copy to return to us, please do so using the email contact information
            provided on our <Link to="/contact/">contact</Link> page. Our postal address is written in the terms and
            conditions opening paragraph.
          </P>
        </Container>

        <Container>
          <Cta to="/">home</Cta>
        </Container>
      </Section>
      <Footer />
    </LayoutMain>
  )
}

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/terms/" }) {
      html
    }
  }
`

export default TermsPage
